/* Carousel.css */
.carousel {
  position: relative;
  overflow: hidden;
  width: 960px;
}

.carousel__track {
  width: calc(320px * 5);
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: transform 0.5s ease-in-out;
}

.carousel__slide {
  position: relative;
  width: 320px;
  height: 440px;
  border-radius: 30px;
  overflow: hidden;
}

.carousel__image {
  width: 320px;
  height: 440px;
  object-fit: cover;
  transition: 0.5s ease;
}

.carousel__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(55, 2, 99);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.6s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel__slide:hover .carousel__overlay {
  height: 100%;
}

.carousel__slide:hover .carousel__image {
  margin-top: -100%;
}

.carousel__title {
  color: white;
  background: linear-gradient(
    97deg,
    rgb(84, 86, 237) 0%,
    rgb(64, 186, 255) 100%
  );
  font-size: 18px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  transition: 0.5s ease;
  z-index: 1999;
  padding: 10px 24px;
  border-radius: 8px;
}

.carousel__slide:hover .carousel__title {
  bottom: 50%;
}

.carousel__controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;
}

.carousel__control {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: all;
  color: white;
  font-size: 24px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .carousel {
    width: 360px;
    overflow: scroll;
  }

  .carousel__slide {
    width: 80%;
    height: auto; /* Adjust height for smaller screens */
  }

  .carousel__track {
    gap: 5px; /* Reduce gap */
    overflow-x: scroll;
  }

  .carousel__title {
    font-size: 14px; /* Adjust font size */
  }

  .carousel__control {
    display: none;
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
}
